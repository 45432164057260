import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <p><strong parentName="p">{`*`}{`Open gym at Louisville East today from 7-9:30am & 2-4pm.`}{`*`}</strong></p>
    <p><strong parentName="p">{`*`}{`True Grit 4 is filled with teams ready to rumble for 1st place on
November 5th! Come out and watch the action at The Ville starting at
9:00am. `}<a parentName="strong" {...{
          "href": "https://truegritcomp.wordpress.com"
        }}>{`https://truegritcomp.wordpress.com`}</a></strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`Next Saturday, October 29th, is our annual Halloween WOD! Dress up
in your best costume and be ready to WOD at 9:00am at The Ville or East
at 10:00am. Best costume at each location wins a prize! The 8:00am Ville
class and 11:00am East class will be cancelled.`}</strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`*`}{`We are collecting blankets, scarves and gloves at Louisville
East and The Ville for the homeless this winter. If you’d like to donate
please do so by November 12th. There are bins at each location for your
donations. Thanks!`}</strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`*`}{`*`}{`The next Strength Program starts Sunday, November 6th. For
more info contact Eric at fallscitystrength\\@gmail.com.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      